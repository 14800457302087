import Header from './header';
import Body from './body';
import Footer from './footer';

// Code-splitting is automated for `routes` directory

const App = () => (
	<div id="app">
		<Header/>
		<Body/>
		<Footer/>
	</div>
)

export default App;
