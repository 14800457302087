import { Component, createRef } from 'preact';
import styles from './styles.scss';
import $ from 'cash-dom';

export default class extends Component {
    constructor(props){
        super();
        const self = this;
        this.ref = createRef();
        this.observer = props.observer;
        this.input = undefined;
        this.button = undefined;
        this.element = undefined;
        this.notify = ()=>{ this.observer.notify('locate store'); }
    }

    componentDidMount(){
        this.element = $(this.ref.current);
        this.input = this.element.find('[name="address"]');
        this.button = this.element.find('button');
        this.button.on('click',this.notify);
    }


    componentWillUnmount(){
        this.button.off('click',this.notify);
    }

    render(){
        return (
            <section ref={this.ref} class={styles.search}>
                <p>Ingresa tu ubicación</p>
                <div class={styles.inputCont}>
                    <input type="text" name="address" />
                    <div data="img">
                        <img  src="/assets/icons/store.png" /> 
                    </div>
                </div>
                <button class={styles.button}>
                    <p>Localizar tienda </p>
                    <div>
                        <img src="/assets/img/pickup.png" />
                    </div>
                </button>
            </section>
        )
    }
}