function Observer(events){
    const Events = {};
    let ID = 0;
  
    this.event = {
      create: (event)=>{
        Events[event] = [];
      },
      delete: (event)=>{
        delete Events[event];
      },
      keys: ()=>{ return Object.keys(Events); },
      get: (event)=>{ return Events[event].map((e)=>{ return e.id }); },
      exist: (event)=>{ return this.event.keys().indexOf(event) != -1 }
    }
  
    this.notify = (event,update)=>{
      Events[event].forEach((sub)=>{ sub.notify.apply(null,(update == undefined ? [] : update)); });
    }
  
    this.register = (event,subscriber)=>{
  
      ID++;
      Events[event].push({id: ID, notify: subscriber});
      return ID;
    }
  
    this.unregister = (event,id)=>{
      Events[event]  = Events[event].reduce((a,c)=>{
        if(c.id !== id){ a.push(c); }
        return a;
  
      },[]);
    }
  
    if(events){ events.forEach(this.event.create); }
  
  }


  export { Observer }
