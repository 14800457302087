import { Component } from 'preact';
import styles from './styles.scss';

export default class extends Component {
    constructor(){
        super();
        
    }

    socials(){
        return [
            {i:'fab fa-facebook-f',link:'https://www.facebook.com/Juguetron/'},
            {i:'fab fa-instagram',link:'https://www.instagram.com/juguetronmx/'},
            {i:'fab fa-youtube',link:'https://www.youtube.com/user/JuguetronOficial'},
            {i:'fab fa-linkedin-in',link:'https://www.linkedin.com/company/grupojuguetron'}
        ].map((icon)=>{
            return ( <div> <a href={icon.link} target="_blank"><i class={icon.i}></i></a> </div> )
        })
    }

    legals(){
        return [
            {text:'Contáctanos',link:'https://www.juguetron.mx/contact/'},
            {text:'Términos y Condiciones',link:'https://www.juguetron.mx/terminos-y-condiciones/'},
            {text:'Políticas de Privacidad',link:'https://www.juguetron.mx/politicas-de-privacidad/'},
            {text:'Preguntas Frecuentes',link:'https://www.juguetron.mx/preguntas-frecuentes/'}
        ].map((legal)=>{
            return (<div>
                <a target="_blank" href={legal.link}>{legal.text}</a>
            </div>)
        })
    }

    render(){
        return (
            <div class={styles.footer}>
                <div>
                    <div data="socials">
                        {this.socials()}
                    </div>
                    <div data="legals">
                        {this.legals()}
                    </div>
                </div>
                <div>
                    <p>2020 Grupo Juguetron / Todos los Derechos Reservados</p>
                </div>
            </div>
        )
    }
}