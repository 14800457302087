import { Component } from 'preact';
import Search from '../search';
import Tabs from '../tabs';
import List from '../list';
import Form from '../form';
import Map from '../map';
import styles from './styles.scss';
import { Observer } from '../helpers';

export default class extends Component {
    constructor(){
        super();
        this.observer = new Observer([
            'pickup clicked',
            'toggle view',
            'locate store',
            'update nearest stores',
            'open form',
            'show store on map'
        ]);

      

        
    }


    render(){
        return (
            <div class={styles.body}>
                <div>
                    <Search observer={this.observer} />
                    <Tabs observer={this.observer} />
                    <List observer={this.observer} />
                </div>
                <Form observer={this.observer}/>
                <Map observer={this.observer}/>
            </div>
        )
    }
}