import { Component, createRef } from 'preact';
import styles from './styles.scss';
import $ from 'cash-dom';

export default class extends Component {
    constructor(props){
        super();
        const self = this;
        this.observer = props.observer;
        this.ref = createRef();
        this.element = undefined;
        this.notify = function(el){ 
            el = $(this);
            let view = el.attr('data-button');
            self.buttons.attr('data','');
            el.attr('data','active');
            self.observer.notify('toggle view',[view]);
        }
    }

    componentDidMount(){
        this.element = $(this.ref.current);
        this.buttons = this.element.find('[data-button]');
        this.buttons.on('click',this.notify);
    }

    render(){
        return (
                <div ref={this.ref} class={styles.tabCont}>
                    <div data-button="list"  data="active">
                        <div data="icon">
                            <i class="fas fa-list"></i>
                            <p>Lista</p>
                        </div>
                        <div data="bar">

                        </div>
                    </div>
                    <div data-button="map" >
                        <div data="icon">
                            <i class="fas fa-map-marker-alt"></i>
                            <p>Mapa</p>
                        </div>
                        <div data="bar">

                        </div>
                    </div>
                </div>
        )
    }
}