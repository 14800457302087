import { Component } from 'preact';
import styles from './styles.scss';

export default class extends Component {

	render(){
		return (
		<header class={styles.header}>
			<a href="https://www.juguetron.mx/" data="home">
				<i class="fas fa-home"></i>
			</a>
			<div data="img">
				<img name="j" src="/assets/img/juguetron-h-s.png" />
				<i name="w" class="fab fa-whatsapp"></i>
				<img name="p" src="/assets/img/pickup-h-s.png" />
			</div>
		</header>)
	}

}
