import { Component, createRef } from 'preact';
import { Loader } from '@googlemaps/js-api-loader';
import $ from 'cash-dom';
import styles from './styles.scss';
import Data from './data.js';
import dataGEO from './dataGEO';
import InfoCard from './info';

export default class extends Component {
    constructor(props) {
        super();
        const self = this; 
        this.ref = createRef();
        this.element = undefined;
        this.autocomplete = undefined;
        this.observer = props.observer;
        this.stores = Data;
        this.map = undefined;
        this.mapOptions = {
            zoom: 15,
            center: { lat: 19.433356, lng: -99.133117 },
        }

        this.observer.register('toggle view',function(view){
            if(view !== 'map'){ self.element.css('display','none'); }
            else{ self.element.css('display','block'); }
        })

        this.observer.register('locate store',function(){
          self.locateStore();
        })

        this.observer.register('show store on map',function(id){
          self.showStoreOnMap(id);
        })

        window.addEventListener('resize',(e)=>{
          if(window.innerWidth > 768){
              this.element.css('display','block');
          } 
          if(window.innerWidth < 768){
            this.element.css('display','none');
        } 
      })


    }

    componentDidMount() {
        this.element = $(this.ref.current);
        this.mapElement = this.element.find('[data="map"]')[0];
        this.loadMap();
    }


    showStoreOnMap = (id)=>{
      const location = this.stores.find((s)=>{ return s.storeid == id }).location;
      const store = new google.maps.LatLng(location.lat,location.lng);
      this.map.panTo(store);
    }

    findClosest = (origin)=>{
        return Data.map((store)=>{
            const { storeid,location } = store;
            let LatLng = new google.maps.LatLng(location.lat,location.lng);
            let distance = google.maps.geometry.spherical.computeDistanceBetween(origin,LatLng);
            return { distance , storeid ,LatLng}
        }).sort((a,b)=>{ return (a.distance - b.distance) }).splice(0,5);
    }

    calculateDistances = async (data, origin)=>{
        const stores = [];
        const destinations = [];
      
        // Build parallel arrays for the store IDs and destinations
        data.forEach((store) => {
          const { storeid , LatLng } = store;
      
          stores.push(storeid);
          destinations.push(LatLng);
        });
      
        // Retrieve the distances of each store from the origin
        // The returned list will be in the same order as the destinations list
        const service = new google.maps.DistanceMatrixService();
        const getDistanceMatrix =
          (service, parameters) => new Promise((resolve, reject) => {
            service.getDistanceMatrix(parameters, (response, status) => {
              if (status != google.maps.DistanceMatrixStatus.OK) {
                reject(response);
              } else {
                const distances = [];
                const results = response.rows[0].elements;
                for (let j = 0; j < results.length; j++) {
                  const element = results[j];
                  const distanceText = element.distance.text;
                  const distanceVal = element.distance.value;
                  const distanceObject = {
                    storeid: stores[j],
                    distanceText: distanceText,
                    distanceVal: distanceVal,
                  };
                  distances.push(distanceObject);
                }
      
                resolve(distances);
              }
            });
          });
      
        const distancesList = await getDistanceMatrix(service, {
          origins: [origin],
          destinations: destinations,
          travelMode: 'DRIVING',
          unitSystem: google.maps.UnitSystem.METRIC,
        });
      
        distancesList.sort((first, second) => {
          return first.distanceVal - second.distanceVal;
        });

      
        return distancesList;
    }

    locateStore = ()=>{
        this.originMarker.setVisible(false);
        let originLocation = this.map.getCenter();
        const place = this.autocomplete.getPlace();

        if(!place){
          window.alert('No address');
          return ;
        }

        if (!place.geometry) {
          window.alert('No address available for input: \'' + place.name + '\'');
          return;
        }
    
        originLocation = place.geometry.location;
        this.map.setCenter(originLocation);
        this.map.setZoom(15);
    
        this.originMarker.setPosition(originLocation);
        this.originMarker.setVisible(true);
        this.observer.notify('update nearest stores',[this.findClosest(originLocation)]);
        return;
    }

    initAutoComplete = ()=>{
        let input = $('body').find('[name="address"]')[0];
        this.autocomplete = new google.maps.places.Autocomplete(input, {
            types: ['address'],
            componentRestrictions: { country: 'mx' },
        });

        this.autocomplete.setFields(['address_components', 'geometry', 'name']);

        this.originMarker = new google.maps.Marker({ map: this.map });
        this.originMarker.setVisible(false);

        this.autocomplete.addListener('place_changed',this.locateStore);
    }

    loadMap = ()=>{
       
        const loader = new Loader({
            apiKey: 'AIzaSyDTjOacKN-DrpscgXtUk6R8QIdwDYnpR-c',
            version: 'weekly',
            libraries: ['places','geometry']
        });
        
        loader.load().then(() => {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.mapOptions.center.lat = position.coords.latitude;
                    this.mapOptions.center.lng = position.coords.longitude;
                });
            }
            this.map = new google.maps.Map(this.mapElement, this.mapOptions);
            this.map.data.addGeoJson(dataGEO);

            this.map.data.setStyle((feature) => {
                return {
                  icon: {
                    url: `/assets/icons/marker.png`,
                    scaledSize: new google.maps.Size(32, 32),
                  },
                };
            });

            const infoWindow = new google.maps.InfoWindow();

            this.map.data.addListener('click', (event) => {
                const name = event.feature.getProperty('name');
                const address = event.feature.getProperty('address');
                const position = event.feature.getGeometry().get();
                const content = InfoCard(name,address);
            
                infoWindow.setContent(content);
                infoWindow.setPosition(position);
                infoWindow.setOptions({pixelOffset: new google.maps.Size(0, -30)});
                infoWindow.open(this.map);
              });
              

            this.initAutoComplete();

           

            return map;

        }).catch((e) => { console.log(e); })
    }

    render() {
        return (
            <div class={styles.map} ref={this.ref}>
                <div id="map" data="map">

                </div>
            </div>
        )
    }
}