import { Component, createRef } from 'preact';
import styles from './styles.scss';
import $ from 'cash-dom';

export default class extends Component {
    constructor(props){
        super();
        const {storeid,name,address,email} = props;
        const self = this;
        this.ref = createRef();
        this.events = {}
        this.observer = props.observer;
        this.element = undefined;
        this.button = undefined;
        this.findStore = function(e){
            let el = $(this);
            let id = el.attr('data-id');
            if(id != '' || id != undefined){
                self.observer.notify('show store on map',[id]);
            }
        }
        this.notify = ()=>{ let {storeid,name,address,email} = this.props; this.observer.notify('open form',[{storeid,name,address,email}]) }
        this.activate = function(){
            let active = this.element.attr('data')
            if(active == 'active'){ }
        }

    }

    componentDidMount(){
        this.element = $(this.ref.current);
        this.button = this.element.find('[data="pickup"');
        this.button.on('click',this.notify);
        this.element.on('click',this.findStore)

    }

    componentWillUnmount(){
        this.button.off('click',this.notify);
        this.element.off('click',this.findStore)

    }


    render({storeid,name,address,hours = '10:00 a 19:00'}){
        return (
            <div ref={this.ref}  data-id={storeid} class={styles.card}>
                <p data="name">{name}</p>
                <p data="address">{address}</p>
                <div data="hours">
                    <p>
                        <i class="far fa-clock"></i>
                        Horario de atención: 
                    </p>
                    <p>
                        {hours}
                    </p>
                </div>

                <div data="button-cont">
                    <button data="pickup">
                        <p>Realizar</p>
                        <div>
                            <img src="/assets/img/pickup-green.png" />
                        </div>
                    </button>
                </div>
            </div>
        )
    }
}