import { Component, createRef } from 'preact';
import { Observer } from '../helpers';
import Stores from '../map/data';
import Card from './card';
import styles from './styles.scss';
import $ from 'cash-dom';

export default class extends Component {
    constructor(props){
        super();
        const self = this;
        this.state = {cards:[]}
        this.element = undefined;
        this.list = undefined;
        this.stores = Stores; 
        this.ref = createRef();
        this.observer = props.observer;
        this.observer.register('toggle view',function(view){
            if(view !== 'list'){ self.element.css('display','none'); }
            else{ self.element.css('display','block'); }
        })

        this.observer.register('update nearest stores',function(stores){
            stores = stores.map((s)=>{ return s.storeid});
            stores = self.stores.reduce((a,c)=>{
                let index = stores.indexOf(c.storeid);
                if( index != -1 ){
                    a[index] = c;
                }
                return a;
            },[]);
            self.list[0].scrollTop = 0;
            self.setState({cards: self.cards(stores) });

        })
    }

    componentDidMount(){
        this.element = $(this.ref.current);
        this.list = this.element.find('[data="list"]');
        this.setState({cards:this.cards(this.stores)})
    }

    cards(stores){
        return stores.map((card)=>{
            return <Card observer={this.observer} {...card} /> 
        })
    }

    render(){ 
        return (
            <div ref={this.ref} class={styles.listCont}>
                <p>
                    Selecciona tu Tienda <br/> más cercana    
                </p>     
                <div data="list" class={styles.list}>
                    {this.state.cards}
                </div>           
            </div>
        )
    }
}