import { Component, createRef } from 'preact';
import styles from './styles.scss';
import $ from 'cash-dom';

export default class extends Component {
    constructor(props){
        super();
        const self = this; 
        this.state = {store:'',address: ''}
        this.ref = createRef();
        this.docBody = $('body'); 
        this.storeData = undefined;
        this.observer = props.observer;
        this.observer.register('open form',function(data){
            self.docBody.css('overflow-y','hidden');
            let {name,address} = data;
            self.storeData = data;
            self.element.css('display','flex');
            self.setState({store:name,address})
        })
    }

 

    componentDidMount(){
        const self = this;
        this.element = $(this.ref.current);
        this.form = this.element.find('[data="form"]');
        this.success = this.element.find('[data="success"]');
        this.close = this.element.find('[data="close"]');
        this.sendBtn = this.form.find('button');

        this.inputs = this.form.find('input');

        this.element.on('click',(e)=>{
            let el = $(e.target);
            if(el.attr('data') == 'main'){
                this.docBody.css('overflow-y','scroll');
                this.element.css('display','none');
                this.inputs.val('');
                this.form.css('display','block');
                this.success.css('display','none');
            }
        })

        this.sendBtn.on('click',(e)=>{
            e.preventDefault();
            let error = false;
            let checked = 2;
            this.inputs.attr('data','');
            this.inputs.each((i,el)=>{
                el = $(el);
                if(el.val() == ''){
                    el.attr('data','error');
                    error = true;
                }
            });

          
            if(!error){
                let data = {
                    storeEmail: this.storeData.email
                }
                this.inputs.each((i,el)=>{
                    if(el.name !== 'service'){
                        data[el.name] = el.value.trim();
                    }
                })
                fetch('https://us-central1-juguetron-pickup.cloudfunctions.net/sendMail',{
                    method: 'POST',
                    mode: 'no-cors', 
                    headers: { 'Content-Type': 'application/json' },
                    redirect: 'follow', 
                    body: JSON.stringify(data) 
                  }).then(()=>{

                      this.form.css('display','none');
                      this.success.css('display','block');
                })
            }
        });

        this.close.on('click',()=>{
            this.docBody.css('overflow-y','scroll');
            this.element.css('display','none');
            this.inputs.val('');
            this.form.css('display','block');
            this.success.css('display','none');
        })
    }

    render(){
        return (
            <div ref={this.ref} data="main" class={styles.cont}>
                <div data="form">
                    <div data="img">
                        <img name="j" src="/assets/img/juguetron-h-s.png" />
                        <i name="w" class="fab fa-whatsapp"></i>
                        <img name="p" src="/assets/img/pickup-h-s.png" />
                    </div>
                    <h5><span>Tienda:</span> {this.state.store} </h5>
                    <h5><span>Dirección:</span> {this.state.address} </h5>
                    <h4>Llena los siguientes campos y nosotros nos contactamos contigo</h4>
                    <div data="inputs">
                        <div data="input-cont">
                            <p>Nombre Completo <span>*</span></p>
                            <input type="text" name="fullname" />
                        </div>
                        <div data="input-cont">
                            <p>Teléfono Móvil <span>*</span></p>
                            <input type="telephone" name="telephone" />
                        </div>
                        <div data="input-cont">
                            <p>Correo Electrónico <span>*</span></p>
                            <input type="email" name="email" />
                        </div>
                        
                       
                        <div data="button-cont">
                            <button>
                                <p>Enviar Solicitud</p>
                            </button>
                        </div>
                    </div>
                </div>
            
                <div data="success">
                    <div data="img">
                        <i class="fab fa-whatsapp"></i>
                        <img src="/assets/img/logo.png"/>
                    </div>
                    <div data="message">
                        <p>
                        ¡Tu registro se ha generado exitosamente!
                        <br/>
                        <br/>
                        Pronto nos pondremos
                        en contacto contigo vía
                        Whatsapp.                            
                        </p>
                        <p data="accent">
                        ¡Espera nuestro
                            <br/>
                        mensaje!
                        </p>
                    </div>
                    <div data="close">
                    <i class="far fa-times-circle"></i>
                    </div>
                </div>
            </div>
        )
    }


}